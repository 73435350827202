import { createTheme } from "@mui/material/styles"

// A custom theme for this website
const theme = createTheme({
  palette: {
    primary: {
      main: "#000065",
    },
    secondary: {
      main: "#DA3433",
    },
  },
  breakpoints: {
    values: {
      base: 0,
      sm: 374,
      md: 767,
      lg: 1023,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: "Lato",
  },
})

export default theme
