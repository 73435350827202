import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"
import LogoImage from "../../static/assets/logo.svg"
import { initPopup } from "typebot-js"

const Header = ({ data }) => {
  const [isActiveMobile, setIsActiveMobile] = useState(false)
  const [isHeaderSticky, setIsHeaderSticky] = useState(false)
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false)
  const { config, locale } = useLocalization()

  useEffect(() => {
    const header = document.querySelector(".header")
    window.addEventListener("scroll", () => {
      setIsHeaderSticky(window.pageYOffset > header.clientHeight)
    })
  }, [])
  const { pathname } = useLocation()

  let path = pathname

  const adjustedLangs = config

  adjustedLangs.forEach(item => (path = path.replace(`${item.code}/`, "")))

  return (
    <header
      className={`header ${isActiveMobile ? "active" : ""} ${
        isHeaderSticky ? "is-fixed" : ""
      }`}
    >
      <div className="shell">
        <div className="header__inner">
          <LocalizedLink to="/" className="logo">
            <span className="hidden">{data.title}</span>
            <img src={LogoImage} alt="captain cyber logo" />
          </LocalizedLink>

          <button
            className={`nav-trigger ${isActiveMobile ? "active" : ""}`}
            type="button"
            onClick={() => setIsActiveMobile(!isActiveMobile)}
          >
            <span className="nav-trigger-icon">
              <span />
            </span>
          </button>

          <div className="header__content">
            <nav className="nav">
              <ul>
                {data.links.map(({ name, link }, index) => (
                  <li key={index}>
                    <LocalizedLink
                      to={link}
                      onClick={() => setIsActiveMobile(false)}
                    >
                      {name}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
            </nav>

            <div className={`lang-switch-dropdown ${isLangMenuOpen && "show"}`}>
              <button
                className="dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                onClick={() => setIsLangMenuOpen(e => !e)}
              >
                {locale}
                <span className="caret"></span>
              </button>
              <ul className={`dropdown-menu ${isLangMenuOpen && "show"}`}>
                {adjustedLangs.map(lang => (
                  <li key={lang.code}>
                    <LocalizedLink
                      to={path}
                      className={`dropdown-item ${
                        lang.code === locale && "selected"
                      }`}
                      language={lang.code}
                      onClick={() => setIsActiveMobile(false)}
                    >
                      {lang.code.toUpperCase()}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
            </div>

            {/* {data.signin_button && (
              <a
                href={data.signin_button.link}
                className="btn-login-btn-login"
                onClick={() => setIsActiveMobile(false)}
              >
                {data.signin_button.name}
              </a>
            )} */}

            {data.try_button && (
              <button
                className="btn btn--primary-alt"
                onClick={() => (
                  initPopup({
                    publishId: "captaincyber-ask-a-demo",
                    delay: 300,
                    backgroundColor: "#ffffff",
                  })
                )}
              >
                {data.try_button.name}
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
