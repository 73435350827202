import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MdxLink, useLocalization } from "gatsby-theme-i18n"
import Header from "./header"
import Footer from "./footer"

// Mui
import { ThemeProvider } from "@mui/material"
import theme from "../theme"

const components = {
  a: MdxLink,
}

const Layout = ({ children }) => {
  const data = useStaticQuery(siteQuery)
  const { locale } = useLocalization()

  const [localizedData] = data.allFile.nodes.filter(
    node => node.childMdx.fields.locale === locale
  )

  const { header, footer } = localizedData.childMdx.frontmatter

  return (
    <div id="top" className="wrapper">
      <ThemeProvider theme={theme}>
        <Header data={header} />
        <main>
          <MDXProvider components={components}>{children}</MDXProvider>
        </main>

        <Footer data={footer} />
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const siteQuery = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "layout" }, extension: { eq: "mdx" } }
    ) {
      nodes {
        childMdx {
          fields {
            locale
          }
          frontmatter {
            header {
              links {
                name
                link
              }
              header_button {
                name
                link
              }
              header_logo {
                alt
                link
              }
              signin_button {
                name
                link
              }
              try_button {
                name
                link
              }
            }
            footer {
              footer_download
              footer_description
              ios_store_image {
                publicURL
              }
              google_store_image {
                publicURL
              }
              columns {
                name
                links {
                  link
                  name
                }
              }
              copyright_links {
                link
                name
              }
              cookies_modal_name
              socials {
                facebook
                twitter
                linkedin
                youtube
              }
            }
          }
        }
      }
    }
  }
`
